<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de seguimiento entregas</p>
          <Button @click="displayModalSegui = true, idSegui = null" class="ml-auto rounded-md" label="Crear Seguimiento" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <!-- filtro por cod -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cod">Código</label>
            <InputText class="w-full" id="cod" @keyup.enter="listarSegui" v-model="filtros.Code" />
          </div>
          <!-- filtro por nombre -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="desc">Nombre seguimiento</label>
            <InputText class="w-full" id="desc" @keyup.enter="listarSegui" v-model="filtros.Name"/>
          </div>
          <div class="flex gap-2">
            <Button @click="listarSegui" class="mt-5 mb-1.5 rounded-md" label="Buscar" />
            <Button @click="limpiarFiltros" class="mt-5 mb-1.5 rounded-md p-button-outlined p-button-secondary" label="Limpiar" />
          </div>
        </div>
        <div class="w-full my-2">
          <DataTable class="p-datatable-sm text-xs" :value="seguimientos.rows" responsiveLayout="scroll">
            <Column header="Cod diag">
              <template #body="{data}">
                <div class=" pt-3">
                  <p>{{ data.Code }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button class="underline italic" @click='EditSegui(data.Id)'>Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="Name" header="Descripción Seguimiento"></Column>

            <Column header="Acción">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" >
                  <Button
                      @click='DeleteSegui(data.Id)'
                      severity="danger"
                      v-tooltip="'Eliminar'"
                      class='p-button-rounded p-button-icon-only mx-2'
                  >
                    <i class="pi pi-trash text-white"></i>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(seguimientos.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
      <Dialog :header="`${idSegui ? 'Actualizar' : 'Crear'} Seguimiento`" v-model:visible="displayModalSegui" :breakpoints="{'960px': '95vw'}" :style="{width: '60vw'}" :modal="true">
        <div class="grid gap-4 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
          <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
            <crearSegui @crearSegui="displayModalSegui = false, listarSegui()" :idSegui="idSegui" />
          </div>
        </div>
      </Dialog>
      <Toast />
    </section>
</template>
<script setup>
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import SeguiminetoService from '../../../../../services/seguimiento_entregas.service'
  import { onMounted, ref, computed } from 'vue'
  import crearSegui from './crear.vue'
    // name: 'ListadoDiagnosticos'
    // Servicios
    const toast = useToast()
    const _SeguiminetoService = ref(new SeguiminetoService())
    // Referencias
    const seguimientos = ref([])
    const limit = ref(10)
    const offset = ref(0)
    const filtros = ref({
      Code: null,
      Name: null
    })
    const idSegui = ref(null)
    const displayModalSegui = ref(false)
    const params = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtros.value
      }
    })
    // Metodos
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarSegui(params.value)
    }
    const listarSegui = () => {
      const object = {
        ...params.value,
        limit: limit.value,
        offset: offset.value
      }
      _SeguiminetoService.value.paginate(object).then(({ data }) => {
        seguimientos.value = data
      })
    }
    const EditSegui = (id) => {
      displayModalSegui.value = true
      idSegui.value = id
    }
    const DeleteSegui = (id) => {
      Swal.fire({
          title: 'Esta seguro de eliminar este seguimiento?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            _SeguiminetoService.value.eliminarSegui(id).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar el seguimiento, por favor intente nuevamente')
              } else {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'seguimiento eliminado exitosamente', life: 3000 })
              }
              listarSegui()
            })
          } else if (result.isDenied) {
            Swal.fire('seguimiento no eliminado', '', 'info')
          }
        })
    }
    const limpiarFiltros = () => {
      filtros.value = {
        nomMpio: null,
        nomDpto: null
      }
      listarSegui()
    }
    onMounted(() => {
      listarSegui()
    })
</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
