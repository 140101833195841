import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class SeguimientoEntregasService {
  listarSeguimientoEntregas () {
    return http.get(`${baseUrl}/seguimiento-entregas/listar`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/seguimiento-entregas/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getSegui (id) {
    return http.get(`${baseUrl}/seguimiento-entregas/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearSegui (data) {
    return http.post(`${baseUrl}/seguimiento-entregas`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarSegui (data) {
    return http.put(`${baseUrl}/seguimiento-entregas/${data.Id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarSegui (id) {
    return http.delete(`${baseUrl}/seguimiento-entregas/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
